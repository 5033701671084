export const accountTypes = {
  BANK_INDIVIDUAL: {
    label: "Bank Owned by Individual",
    value: "bankIndividual",
  },

  BANK_COMPANY: { label: "Bank Owned by Company", value: "bankCompany" },

  CRYPTOCURRENCY_WALLET: { label: "Cryptocurrency Wallet", value: "crypto" },
};

export const purposeTypes = {
  RESEARCHER: "Researcher Payout",
  AFFILIATE: "Affiliate Payout",
  SUPPORT: "Support Staff Payout",
  TECHNOLOGY: "Technology Staff Payout",
  MANAGEMENT: "Management Staff Payout",
  IFXTEST: "IFX Test Payout",
};

export const bankTypes = [
  { code: "AD", full: "Andorra", requirements: ["IBAN", "BIC"] },
  { code: "AE", full: "United Arab Emirates", requirements: ["IBAN", "BIC"] },
  {
    code: "AG",
    full: "Antigua and Barbuda",
    requirements: ["Account Number", "BIC"],
  },
  { code: "AI", full: "Anguilla", requirements: ["Account Number", "BIC"] },
  { code: "AL", full: "Albania", requirements: ["IBAN", "BIC"] },
  { code: "AM", full: "Armenia", requirements: ["Account Number", "BIC"] },
  { code: "AR", full: "Argentina", requirements: ["Account Number", "BIC"] },
  {
    code: "AS",
    full: "American Samoa",
    requirements: ["Account Number", "BIC"],
  },
  { code: "AT", full: "Austria", requirements: ["IBAN", "BIC"] },
  { code: "AU", full: "Australia", requirements: ["Account Number", "BIC"] },
  { code: "AW", full: "Aruba", requirements: ["Account Number", "BIC"] },
  { code: "AX", full: "Åland Islands", requirements: ["IBAN", "BIC"] },
  { code: "AZ", full: "Azerbaijan", requirements: ["Account Number", "BIC"] },
  { code: "BB", full: "Barbados", requirements: ["Account Number", "BIC"] },
  { code: "BD", full: "Bangladesh", requirements: ["Account Number", "BIC"] },
  { code: "BE", full: "Belgium", requirements: ["IBAN", "BIC"] },
  { code: "BG", full: "Bulgaria", requirements: ["IBAN", "BIC"] },
  { code: "BH", full: "Bahrain", requirements: ["IBAN", "BIC"] },
  { code: "BJ", full: "Benin", requirements: ["Account Number", "BIC"] },
  { code: "BL", full: "Saint Barthélemy", requirements: ["IBAN", "BIC"] },
  { code: "BM", full: "Bermuda", requirements: ["Account Number", "BIC"] },
  {
    code: "BN",
    full: "Brunei Darussalam",
    requirements: ["Account Number", "BIC"],
  },
  {
    code: "BQ",
    full: "Bonaire, Saint Eustatius and Saba",
    requirements: ["Account Number", "BIC"],
  },
  { code: "BR", full: "Brazil", requirements: ["Account Number", "BIC"] },
  { code: "BS", full: "Bahamas", requirements: ["Account Number", "BIC"] },
  { code: "BT", full: "Bhutan", requirements: ["Account Number", "BIC"] },
  { code: "BW", full: "Botswana", requirements: ["Account Number", "BIC"] },
  {
    code: "BV",
    full: "Bouvet Island",
    requirements: ["Account Number", "BIC"],
  },
  { code: "BZ", full: "Belize", requirements: ["Account Number", "BIC"] },
  { code: "CA", full: "Canada", requirements: ["Account Number", "BIC"] },
  { code: "CC", full: "Cocos Island", requirements: ["Account Number", "BIC"] },
  { code: "CH", full: "Switzerland", requirements: ["IBAN", "BIC"] },
  { code: "CK", full: "Cook Islands", requirements: ["Account Number", "BIC"] },
  { code: "CL", full: "Chile", requirements: ["Account Number", "BIC"] },
  { code: "CM", full: "Cameroon", requirements: ["Account Number", "BIC"] },
  { code: "CN", full: "China", requirements: ["Account Number", "BIC"] },
  { code: "CO", full: "Colombia", requirements: ["Account Number", "BIC"] },
  { code: "CR", full: "Costa Rica", requirements: ["IBAN", "BIC"] },
  { code: "CV", full: "Cape Verde", requirements: ["Account Number", "BIC"] },
  { code: "CW", full: "Curaçao", requirements: ["Account Number", "BIC"] },
  {
    code: "cx",
    full: "Christmas Island",
    requirements: ["Account Number", "BIC"],
  },
  { code: "CY", full: "Cyprus", requirements: ["IBAN", "BIC"] },
  { code: "CZ", full: "Czech Republic", requirements: ["IBAN", "BIC"] },
  { code: "DE", full: "Germany", requirements: ["IBAN", "BIC"] },
  { code: "DJ", full: "Djibouti", requirements: ["Account Number", "BIC"] },
  { code: "DK", full: "Denmark", requirements: ["IBAN", "BIC"] },
  { code: "DM", full: "Dominica", requirements: ["Account Number", "BIC"] },
  { code: "DO", full: "Dominican Republic", requirements: ["IBAN", "BIC"] },
  { code: "EC", full: "Ecuador", requirements: ["Account Number", "BIC"] },
  { code: "EE", full: "Estonia", requirements: ["IBAN", "BIC"] },
  {
    code: "EH",
    full: "Western Sahara",
    requirements: ["Account Number", "BIC"],
  },
  { code: "ES", full: "Spain", requirements: ["IBAN", "BIC"] },
  { code: "ET", full: "Ethiopia", requirements: ["Account Number", "BIC"] },
  { code: "FI", full: "Finland", requirements: ["IBAN", "BIC"] },
  { code: "FJ", full: "Fiji", requirements: ["Account Number", "BIC"] },
  {
    code: "FK",
    full: "Falkland Islands",
    requirements: ["Account Number", "BIC"],
  },
  { code: "FM", full: "Micronesia", requirements: ["Account Number", "BIC"] },
  { code: "FO", full: "Faroe Islands", requirements: ["IBAN", "BIC"] },
  { code: "FR", full: "France", requirements: ["IBAN", "BIC"] },
  { code: "GA", full: "Gabon", requirements: ["Account Number", "BIC"] },

  { code: "GB", full: "United Kingdom", requirements: ["IBAN", "BIC"] },
  { code: "GD", full: "Grenada", requirements: ["Account Number", "BIC"] },
  { code: "GE", full: "Georgia", requirements: ["IBAN", "BIC"] },
  { code: "GF", full: "French Guiana", requirements: ["IBAN", "BIC"] },
  {
    code: "GG",
    full: "Guernsey",
    requirements: ["Account Number", "Sort Code"],
  },
  { code: "GH", full: "Ghana", requirements: ["Account Number", "BIC"] },
  {
    code: "GI",
    full: "Gibraltar",
    requirements: ["Account Number", "Sort Code"],
  },
  { code: "GL", full: "Greenland", requirements: ["IBAN", "BIC"] },
  { code: "GM", full: "Gambia", requirements: ["Account Number", "BIC"] },
  { code: "GP", full: "Guadeloupe", requirements: ["IBAN", "BIC"] },
  {
    code: "GQ",
    full: "Equatorial Guinea",
    requirements: ["Account Number", "BIC"],
  },
  { code: "GR", full: "Greece", requirements: ["IBAN", "BIC"] },
  {
    code: "GS",
    full: "South Georgia and the South Sandwich Islands",
    requirements: ["Account Number", "BIC"],
  },
  { code: "GT", full: "Guatemala", requirements: ["Account Number", "BIC"] },
  { code: "GU", full: "Guam", requirements: ["Account Number", "BIC"] },
  { code: "HK", full: "Hong Kong", requirements: ["Account Number", "BIC"] },
  {
    code: "HM",
    full: "Heard Island and McDonald Islands",
    requirements: ["Account Number", "BIC"],
  },
  { code: "HN", full: "Honduras", requirements: ["Account Number", "BIC"] },
  { code: "HR", full: "Croatia", requirements: ["IBAN", "BIC"] },
  { code: "HU", full: "Hungary", requirements: ["IBAN", "BIC"] },
  { code: "ID", full: "Indonesia", requirements: ["Account Number", "BIC"] },
  { code: "IE", full: "Ireland", requirements: ["IBAN", "BIC"] },
  { code: "IL", full: "Israel", requirements: ["IBAN", "BIC"] },
  {
    code: "IM",
    full: "Isle of Man",
    requirements: ["Account Number", "Sort Code"],
  },
  { code: "IN", full: "India", requirements: ["Account Number", "BIC"] },
  {
    code: "IO",
    full: "British Indian Ocean Territory",
    requirements: ["Account Number", "BIC"],
  },
  { code: "IS", full: "Iceland", requirements: ["IBAN", "BIC"] },
  { code: "IT", full: "Italy", requirements: ["IBAN", "BIC"] },
  { code: "JE", full: "Jersey", requirements: ["Account Number", "Sort Code"] },
  { code: "JM", full: "Jamaica", requirements: ["Account Number", "BIC"] },
  { code: "JO", full: "Jordan", requirements: ["IBAN", "BIC"] },
  { code: "JP", full: "Japan", requirements: ["Account Number", "BIC"] },
  { code: "KE", full: "Kenya", requirements: ["Account Number", "BIC"] },
  { code: "KG", full: "Kyrgyzstan", requirements: ["Account Number", "BIC"] },
  { code: "KI", full: "Kiribati", requirements: ["Account Number", "BIC"] },
  { code: "KM", full: "Comoros", requirements: ["Account Number", "BIC"] },
  {
    code: "KN",
    full: "Saint Kitts and Nevis",
    requirements: ["Account Number", "BIC"],
  },
  { code: "KR", full: "South Korea", requirements: ["Account Number", "BIC"] },
  { code: "KW", full: "Kuwait", requirements: ["IBAN", "BIC"] },
  {
    code: "KY",
    full: "Cayman Islands",
    requirements: ["Account Number", "BIC"],
  },
  { code: "KZ", full: "Kazakhstan", requirements: ["IBAN", "BIC"] },
  { code: "LC", full: "Saint Lucia", requirements: ["Account Number", "BIC"] },
  { code: "LI", full: "Liechtenstein", requirements: ["IBAN", "BIC"] },
  { code: "LK", full: "Sri Lanka", requirements: ["Account Number", "BIC"] },
  { code: "LS", full: "Lesotho", requirements: ["Account Number", "BIC"] },
  { code: "LT", full: "Lithuania", requirements: ["IBAN", "BIC"] },
  { code: "LU", full: "Luxembourg", requirements: ["IBAN", "BIC"] },
  { code: "LV", full: "Latvia", requirements: ["IBAN", "BIC"] },
  { code: "MA", full: "Morocco", requirements: ["Account Number", "BIC"] },
  { code: "MC", full: "Monaco", requirements: ["IBAN", "BIC"] },
  { code: "MD", full: "Moldova", requirements: ["IBAN", "BIC"] },
  { code: "ME", full: "Montenegro", requirements: ["IBAN", "BIC"] },
  { code: "MF", full: "Saint Martin", requirements: ["IBAN", "BIC"] },
  { code: "MG", full: "Madagascar", requirements: ["IBAN", "BIC"] },
  {
    code: "MH",
    full: "Marshall Islands",
    requirements: ["Account Number", "BIC"],
  },
  { code: "MK", full: "North Macedonia", requirements: ["IBAN", "BIC"] },
  { code: "ML", full: "Mali", requirements: ["Account Number", "BIC"] },
  { code: "MN", full: "Mongolia", requirements: ["Account Number", "BIC"] },
  { code: "MO ", full: "Macao", requirements: ["Account Number", "BIC"] },
  {
    code: "MP",
    full: "Northern Mariana Islands",
    requirements: ["Account Number", "BIC"],
  },
  { code: "MQ", full: "Martinique", requirements: ["IBAN", "BIC"] },
  { code: "MR", full: "Mauritania", requirements: ["IBAN", "BIC"] },
  { code: "MT", full: "Malta", requirements: ["IBAN", "BIC"] },
  { code: "MU", full: "Mauritius", requirements: ["IBAN", "BIC"] },
  { code: "MV", full: "Maldives", requirements: ["Account Number", "BIC"] },
  { code: "MW", full: "Malawi", requirements: ["Account Number", "BIC"] },
  { code: "MX", full: "Mexico", requirements: ["BIC", "CLABE"] },
  { code: "MY", full: "Malaysia", requirements: ["Account Number", "BIC"] },
  { code: "NA", full: "Namibia", requirements: ["Account Number", "BIC"] },
  {
    code: "NC",
    full: "New Caledonia",
    requirements: ["Account Number", "BIC"],
  },
  { code: "NE", full: "Niger", requirements: ["Account Number", "BIC"] },
  {
    code: "NF",
    full: "Norfolk Island",
    requirements: ["Account Number", "BIC"],
  },
  { code: "NI", full: "Nicaragua", requirements: ["Account Number", "BIC"] },
  { code: "NL", full: "Netherlands", requirements: ["IBAN", "BIC"] },
  { code: "NO", full: "Norway", requirements: ["IBAN", "BIC"] },
  { code: "NP", full: "Nepal", requirements: ["Account Number", "BIC"] },
  { code: "NR", full: "Nauru", requirements: ["Account Number", "BIC"] },
  { code: "NU", full: "Niue", requirements: ["Account Number", "BIC"] },
  { code: "NZ", full: "New Zealand", requirements: ["Account Number", "BIC"] },
  { code: "OM", full: "Oman", requirements: ["Account Number", "BIC"] },
  { code: "PE", full: "Peru", requirements: ["Account Number", "BIC"] },
  {
    code: "PF",
    full: "French Polynesia",
    requirements: ["Account Number", "BIC"],
  },
  {
    code: "PG",
    full: "Papua New Guinea",
    requirements: ["Account Number", "BIC"],
  },
  { code: "PH", full: "Philippines", requirements: ["Account Number", "BIC"] },
  { code: "PL", full: "Poland", requirements: ["IBAN", "BIC"] },
  {
    code: "PM",
    full: "Saint Pierre and Miquelon",
    requirements: ["IBAN", "BIC"],
  },
  { code: "PN", full: "Pitcairn", requirements: ["Account Number", "BIC"] },
  { code: "PR", full: "Puerto Rico", requirements: ["Account Number", "BIC"] },
  { code: "PT", full: "Portugal", requirements: ["IBAN", "BIC"] },
  { code: "PY", full: "Paraguay", requirements: ["Account Number", "BIC"] },
  { code: "QA", full: "Qatar", requirements: ["IBAN", "BIC"] },
  { code: "RE", full: "Reunion", requirements: ["IBAN", "BIC"] },
  { code: "RO", full: "Romania", requirements: ["IBAN", "BIC"] },
  { code: "RS", full: "Serbia", requirements: ["IBAN", "BIC"] },
  { code: "RU", full: "Russia", requirements: ["Account Number", "BIC"] },
  { code: "RW", full: "Rwanda", requirements: ["Account Number", "BIC"] },
  { code: "SA", full: "Saudi Arabia", requirements: ["IBAN", "BIC"] },
  {
    code: "SB",
    full: "Solomon Islands",
    requirements: ["Account Number", "BIC"],
  },
  { code: "SC", full: "Seychelles", requirements: ["Account Number", "BIC"] },
  { code: "SE", full: "Sweden", requirements: ["IBAN", "BIC"] },
  { code: "SG", full: "Singapore", requirements: ["Account Number", "BIC"] },
  { code: "SH", full: "Saint Helena", requirements: ["Account Number", "BIC"] },
  { code: "SI", full: "Slovenia", requirements: ["IBAN", "BIC"] },
  {
    code: "SJ",
    full: "Svalbard and Jan Mayen",
    requirements: ["Account Number", "BIC"],
  },
  { code: "SK", full: "Slovakia", requirements: ["IBAN", "BIC"] },
  { code: "SM", full: "San Marino", requirements: ["IBAN", "BIC"] },
  { code: "SN", full: "Senegal", requirements: ["Account Number", "BIC"] },
  { code: "SR", full: "Suriname", requirements: ["Account Number", "BIC"] },
  {
    code: "ST",
    full: "Sao Tome and Principe",
    requirements: ["Account Number", "BIC"],
  },
  { code: "SV", full: "El Salvador", requirements: ["Account Number", "BIC"] },
  {
    code: "SX ",
    full: "Sint Maarten",
    requirements: ["Account Number", "BIC"],
  },
  {
    code: "TC",
    full: "Turks and Caicos Island",
    requirements: ["Account Number", "BIC"],
  },
  { code: "TD", full: "Chad", requirements: ["Account Number", "BIC"] },
  {
    code: "TF",
    full: "French Southern Territories",
    requirements: ["IBAN", "BIC"],
  },
  { code: "TG", full: "Togo", requirements: ["Account Number", "BIC"] },
  { code: "TH", full: "Thailand", requirements: ["Account Number", "BIC"] },
  { code: "TK", full: "Tokelau", requirements: ["Account Number", "BIC"] },
  { code: "TM", full: "Turkmenistan", requirements: ["Account Number", "BIC"] },
  { code: "TO", full: "Tonga", requirements: ["Account Number", "BIC"] },
  { code: "TR", full: "Turkey", requirements: ["IBAN", "BIC"] },
  {
    code: "TT",
    full: "Trinidad and Tobago",
    requirements: ["Account Number", "BIC"],
  },
  { code: "TV", full: "Tuvalu", requirements: ["Account Number", "BIC"] },
  { code: "TW", full: "Taiwan", requirements: ["Account Number", "BIC"] },
  { code: "TZ", full: "Tanzania", requirements: ["Account Number", "BIC"] },
  {
    code: "UM",
    full: "United States Minor Outlying Islands",
    requirements: ["Account Number", "BIC"],
  },
  {
    code: "US",
    full: "United States",
    requirements: ["Routing Number", "Account Number"],
  },
  { code: "UY", full: "Uruguay", requirements: ["Account Number", "BIC"] },
  { code: "UZ", full: "Uzbekistan", requirements: ["Account Number", "BIC"] },
  { code: "VA ", full: "Vatican", requirements: ["IBAN", "BIC"] },
  {
    code: "VC",
    full: "Saint Vincent and the Grenadines",
    requirements: ["Account Number", "BIC"],
  },
  { code: "VG", full: "British Virgin Islands", requirements: ["IBAN", "BIC"] },
  {
    code: "VI",
    full: "U.S. Virgin Islands",
    requirements: ["Account Number", "BIC"],
  },
  { code: "VN", full: "Vietnam", requirements: ["Account Number", "BIC"] },
  {
    code: "WF",
    full: "Wallis and Futuna",
    requirements: ["Account Number", "BIC"],
  },
  { code: "WS", full: "Samoa", requirements: ["Account Number", "BIC"] },
  { code: "XK", full: "Kosovo", requirements: ["Account Number", "BIC"] },
  { code: "YT", full: "Mayotte", requirements: ["IBAN", "BIC"] },
  { code: "ZA", full: "South Africa", requirements: ["Account Number", "BIC"] },
  { code: "ZM", full: "Zambia", requirements: ["Account Number", "BIC"] },
];
