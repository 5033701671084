import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import React from "react";
import { accountTypes } from "views/form/constant";
import { Select } from "chakra-react-select";
import { Controller } from "react-hook-form";

const PayoutOptions = ({ control }) => {
  return (
    <>
      <FormControl gap={2} display="flex" alignItems="center">
        <Controller
          control={control}
          name="payoutOptions"
          defaultValue={Object.values(accountTypes)}
          rules={{ required: "Please enter at least one payment option." }}
          render={({
            field: { onChange, value, name, ref },
            fieldState: { error },
          }) => (
            <FormControl py={4} isInvalid={!!error} id="food">
              <FormLabel>Payout Options</FormLabel>

              <Select
                isMulti
                name={name}
                ref={ref}
                onChange={onChange}
                value={value}
                options={Object.values(accountTypes)}
                placeholder="Available Payout Options"
                closeMenuOnSelect={false}
              />

              <FormErrorMessage>{error && error.message}</FormErrorMessage>
            </FormControl>
          )}
        />
      </FormControl>
    </>
  );
};

export default PayoutOptions;
